import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
// material
import { Stack, Typography, TextField, Container, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Toaster, toast } from 'react-hot-toast';
import Api, { KY } from 'src/Api';

const Add = (props) => {
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Enter class name'),
    monthly_fees_amount: Yup.number().required('Enter Monthly Fees'),
    admission_amount: Yup.number().required('Enter admission amount')
  });
  const submitForm = async (data) => {
    const result = await KY.post(Api.class.add, { json: data }).json();
    if (result.status) {
      props.onClose();
      formik.resetForm();
      return 'Success';
    }
    throw new Error(result.message);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      monthly_fees_amount: '',
      admission_amount: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (data) =>
      toast
        .promise(submitForm(data), {
          loading: 'Loading',
          success: (success) => success,
          error: ({ message }) => message
        })
        .then(() => props.reload())
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Container>
      <Toaster containerStyle={{ zIndex: 11010 }} position="bottom-center" />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ mb: 5, mt: 5 }}>
            <Typography variant="h4" gutterBottom>
              Add New Class
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}> Add New Class.</Typography>
          </Stack>

          <Stack spacing={3} sx={{ mb: 2 }}>
            <TextField
              fullWidth
              type="text"
              label="Class Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              type="number"
              label="Monthly Fees (₹)"
              {...getFieldProps('monthly_fees_amount')}
              error={Boolean(touched.monthly_fees_amount && errors.monthly_fees_amount)}
              helperText={touched.monthly_fees_amount && errors.monthly_fees_amount}
            />

            <TextField
              fullWidth
              type="number"
              label="Admission Fees (₹)"
              {...getFieldProps('admission_amount')}
              error={Boolean(touched.admission_amount && errors.admission_amount)}
              helperText={touched.admission_amount && errors.admission_amount}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Form>
        <Button
          onClick={() => props.onClose()}
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          color="error"
          sx={{ mt: 1 }}
        >
          Close
        </Button>
      </FormikProvider>
    </Container>
  );
};
Add.propTypes = {
  onClose: PropTypes.func,
  reload: PropTypes.func
};
export default Add;
