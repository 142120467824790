import { useMemo, useState, useEffect } from 'react';
// material
import { Stack, Avatar, Container, Typography, Button } from '@mui/material';
import { useQuery } from 'react-query';
import ky from 'ky';
import Drawer from '@mui/material/Drawer';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import Api from 'src/Api';

// components
import Page from '../../components/Page';
//
import Tables from '../../components/Tables';
import TableMenu from '../../components/TableMenu';
import Add from './Add';
import Edit from './Edit';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function User() {
  // Status
  const [tableData, setTableData] = useState([]);
  const [addArea, showAddArea] = useState(false);
  const [editArea, showEditArea] = useState(false);
  const [editId, setEditId] = useState(null); //

  const { data, refetch } = useQuery(
    'classList',
    async () =>
      await ky
        .post(Api.class.list, {
          headers: { Authorization: sessionStorage.getItem('authKey') }
          // json: { subjectId, search },
        })
        .json()
  );

  useEffect(() => {
    if (!data) return;
    const tData = data.data.map((item, index) => ({
      ...item,
      name: (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={item.name} src={item.thumb} />
          <Typography variant="subtitle2" noWrap>
            {item.name}
          </Typography>
        </Stack>
      ),
      Action: (
        <TableMenu
          key={`action_table_${index}`}
          edit={`/class/edit/${item.uid}`}
          // view={`class/view/${item.id}`}
          showEditArea={showEditArea}
          deleteUrl={Api.class.delete(item.uid)}
          setEditId={() => setEditId(item.uid)}
          reload={refetch}
        />
      )
    }));
    setTableData(tData);
  }, [data, refetch]);
  const headers = useMemo(
    () => [
      {
        Header: 'SL',
        accessor: 'sl',
        align: 'center'
      },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Fee', accessor: 'amount' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Action',
        accessor: 'Action'
      }
    ],
    []
  );
  return (
    <Page title="Class | Schoolwings">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Class
          </Typography>
          <Button
            variant="contained"
            onClick={() => showAddArea(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New
          </Button>
        </Stack>

        <Drawer
          className="fromDrawer"
          open={addArea}
          anchor="right"
          onClose={() => showAddArea(false)}
        >
          <Add onClose={() => showAddArea(false)} reload={refetch} />
        </Drawer>
        <Drawer
          className="fromDrawer"
          open={editArea}
          anchor="right"
          onClose={() => showEditArea(false)}
        >
          <Edit onClose={() => showEditArea(false)} reload={refetch} editId={editId} />
        </Drawer>

        <Tables headers={headers} tableData={tableData} reload={refetch} />
      </Container>
    </Page>
  );
}
