import { useMemo, useState, useEffect } from 'react';
// material
import { Stack, Container, Typography, Button } from '@mui/material';
import { useQuery } from 'react-query';
import Drawer from '@mui/material/Drawer';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import Api, { KY } from '../../Api';

// components
import Page from '../../components/Page';
//
import Tables from '../../components/Tables';
import TableMenu from '../../components/TableMenu';
import Add from './Add';
import Edit from './Edit';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Sections() {
  // Status
  const [tableData, setTableData] = useState([]);
  const [addArea, showAddArea] = useState(false);
  const [editArea, showEditArea] = useState(false);
  const [editId, setEditId] = useState(null); //

  const { data, refetch } = useQuery(
    'sectionList',
    async () => await KY.post(Api.sections.list).json()
  );

  useEffect(() => {
    if (!data) return;
    const tData = data.data.map((item, index) => ({
      ...item,
      Action: (
        <TableMenu
          key={`action_table_${index}`}
          edit={`/class/edit/${item.uid}`}
          // view={`class/view/${item.id}`}
          showEditArea={showEditArea}
          deleteUrl={Api.sections.delete(item.uid)}
          setEditId={() => setEditId(item.uid)}
          reload={refetch}
        />
      )
    }));
    setTableData(tData);
  }, [data, refetch]);
  const headers = useMemo(
    () => [
      {
        Header: 'SL',
        accessor: 'sl',
        align: 'center'
      },
      { Header: 'Class', accessor: 'class_name' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Action',
        accessor: 'Action'
      }
    ],
    []
  );
  return (
    <Page title="Sections | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sections
          </Typography>
          <Button
            variant="contained"
            onClick={() => showAddArea(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New
          </Button>
        </Stack>

        <Drawer
          className="fromDrawer"
          open={addArea}
          anchor="right"
          onClose={() => showAddArea(false)}
        >
          <Add onClose={() => showAddArea(false)} reload={refetch} />
        </Drawer>
        <Drawer
          className="fromDrawer"
          open={editArea}
          anchor="right"
          onClose={() => showEditArea(false)}
        >
          <Edit onClose={() => showEditArea(false)} reload={refetch} editId={editId} />
        </Drawer>

        <Tables headers={headers} tableData={tableData} reload={refetch} />
      </Container>
    </Page>
  );
}
