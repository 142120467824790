import { atom, selector } from 'recoil';
import Api, { KY } from './Api';

export const userDetails = atom({
  key: 'userDetails', // unique ID (with respect to other atoms/selectors)
  default: '' // default value (aka initial value)
});

export const bearerToken = atom({
  key: 'bearerToken', // unique ID (with respect to other atoms/selectors)
  default: '' // default value (aka initial value)
});

export const ForceReload = atom({
  key: 'forceReload',
  default: 0
});

export const classLists = selector({
  key: 'classLists',
  get: async ({ get }) => {
    const token = get(bearerToken);
    get(ForceReload);
    if (token) {
      const result = await KY.post(Api.class.list).json();
      if (result?.data) return result.data;
      return [];
    }
    return [];
  }
});
export const sectionLists = selector({
  key: 'sectionList',
  get: async ({ get }) => {
    const token = get(bearerToken);
    get(ForceReload);
    if (token) {
      const result = await KY.post(Api.sections.list).json();
      if (result?.data) return result.data;
      return [];
    }
    return [];
  }
});
const MyRecoil = {
  userDetails,
  bearerToken
};
export default MyRecoil;
