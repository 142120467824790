import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

// material
import {
  Stack,
  InputLabel,
  FormHelperText,
  Typography,
  TextField,
  Container,
  Button,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Toaster, toast } from 'react-hot-toast';
import Api, { KY } from '../../Api';

const Add = (props) => {
  const { data: classData } = useQuery(
    'classList',
    async () => await KY.post(Api.class.list).json()
  );

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Enter class name'),
    student_class_id: Yup.string().required('Select a class')
  });
  const submitForm = async (data) => {
    const result = await KY.post(Api.subjects.add, { json: data }).json();
    if (result.status) {
      props.onClose();
      formik.resetForm();
      return 'Success';
    }
    throw new Error(result.message);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      student_class_id: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (data) =>
      toast
        .promise(submitForm(data), {
          loading: 'Loading',
          success: (success) => success,
          error: ({ message }) => message
        })
        .then(() => props.reload())
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Container>
      <Toaster containerStyle={{ zIndex: 11010 }} position="bottom-center" />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ mb: 5, mt: 5 }}>
            <Typography variant="h4" gutterBottom>
              Add New Subject
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}> Add New Subject.</Typography>
          </Stack>

          <Stack spacing={3} sx={{ mb: 2 }}>
            <FormControl error={Boolean(touched.student_class_id && errors.student_class_id)}>
              <InputLabel>Class</InputLabel>
              <Select label="Class" {...getFieldProps('student_class_id')}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {classData?.data?.map((item, index) => (
                  <MenuItem key={`indexSelect${index}`} value={item?.uid}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{touched.student_class_id && errors.student_class_id}</FormHelperText>
            </FormControl>

            <TextField
              fullWidth
              type="text"
              label="Subject Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Form>
        <Button
          onClick={() => props.onClose()}
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          color="error"
          sx={{ mt: 1 }}
        >
          Close
        </Button>
      </FormikProvider>
    </Container>
  );
};
Add.propTypes = {
  onClose: PropTypes.func,
  reload: PropTypes.func
};
export default Add;
