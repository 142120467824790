import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import { Toaster, toast } from 'react-hot-toast';
import { KY } from '../Api';
// ----------------------------------------------------------------------
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function TableMenu(props) {
  const { deleteUrl, reload, showEditArea, setEditId } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  // const [backdrop, showBackdrop] = useState(false);
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpen(true);
  };

  const handleEditClick = () => {
    setIsOpen(false);
    showEditArea(true);
    setEditId();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteConfirm = async () => {
    setOpen(false);
    // showBackdrop(true);
    const deleteResult = await KY.post(deleteUrl).json();

    if (deleteResult.status === true) {
      return deleteResult.message;
    }
    throw new Error(deleteResult.message);
  };
  return (
    <>
      <Toaster containerStyle={{ zIndex: 11010 }} position="bottom-center" />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure? you want to delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="success">
            No
          </Button>
          <Button
            onClick={async () => {
              await toast.promise(deleteConfirm(), {
                loading: 'Deleting',
                success: (data) => data,
                error: (data) => data
              });
              reload();
            }}
            variant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleEditClick}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
TableMenu.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  edit: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  view: PropTypes.string,
  deleteUrl: PropTypes.string,
  reload: PropTypes.func,
  showEditArea: PropTypes.func,
  setEditId: PropTypes.func
  //   history: PropTypes.object
};
