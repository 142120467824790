import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@mui/material';
// import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
// import routes from 'src/routes';
import {
  useTable,
  usePagination,
  // useBlockLayout,
  useSortBy
} from 'react-table';
import Scrollbar from './Scrollbar';

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
const Tables = ({ headers, tableData = '' }) => {
  // const currentLocation = useLocation().pathname;

  //   const getRouteName = () => {
  //     const currentRoute = routes.find((route) => route.path === currentLocation);
  //     return currentRoute.name;
  //   };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({ columns: headers, data: tableData }, useSortBy, usePagination);
  return (
    <div>
      <Styles>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <div className="tableWrap">
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, j) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()} key={`rows${j}`}>
                        {headerGroup.headers.map((column, i) => (
                          <TableCell
                            {...column?.attr}
                            key={`table_keys${i}`}
                            {...column.getHeaderProps({
                              style: {
                                borderBottom: 'solid 3px red',
                                background: 'aliceblue',
                                color: 'black',
                                fontWeight: 'bold'
                                // ...column?.style,
                              },
                              ...column.getSortByToggleProps()
                            })}
                            // style={{
                            //   borderBottom: 'solid 3px red',
                            //   background: 'aliceblue',
                            //   color: 'black',
                            //   fontWeight: 'bold',
                            //   ...column?.style,
                            // }}
                          >
                            {column.render('Header')}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    {
                      // Loop over the table rows
                      page.map((row, k) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <TableRow {...row.getRowProps()} key={`rows1_${k}`}>
                            {
                              // Loop over the rows cells
                              row.cells.map((cell, l) => (
                                <TableCell
                                  {...cell.getCellProps()}
                                  key={`rows2_${l}`}
                                  align={l === 0 ? 'center' : 'inherit'}
                                >
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </TableCell>
                              ))
                            }
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={(e, p) => gotoPage(p)}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </Card>
      </Styles>
    </div>
  );
};
Tables.propTypes = {
  tableUrl: PropTypes.string,
  headers: PropTypes.array,
  tableData: PropTypes.array,
  addPath: PropTypes.string,
  title: PropTypes.string
  // TableFilters: PropTypes.func,
};
export default Tables;
