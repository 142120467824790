import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import ky from 'ky';
import { useSetRecoilState } from 'recoil';
import Api from '../../Api';

//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import { userDetails, bearerToken } from '../../MyRecoil';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const setAuth = useSetRecoilState(userDetails);
  const setToken = useSetRecoilState(bearerToken);

  const { data, isLoading } = useQuery(
    'fetchSchoolDetails',
    async () =>
      await ky
        .post(Api.auth.login, {
          headers: { Authorization: sessionStorage.getItem('authKey') }
        })
        .json()
  );
  useEffect(() => {
    if (!isLoading && data && data.status) {
      sessionStorage.setItem('authKey', data?.user?.Auth);
      setAuth(data?.user);
      setToken(data?.user?.Auth);
    } else if (!isLoading && data && !data.status) {
      // Auth , expired,  Return to Login
      sessionStorage.removeItem('authKey');
      setAuth(null);
      setToken(null);
      navigate('/login', { replace: true });
    }
  }, [data, isLoading, navigate, setAuth, setToken]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
