import ky from 'ky';

export const baseUrl = process.env.REACT_APP_API_URL;
export const api = `${baseUrl}/schoolapi`;
const Api = {
  baseUrl,
  auth: {
    login: `${api}/login`
  },
  students: {
    list: `${api}/students`,
    view: (id) => `${api}/students/${id}`,
    add: `${api}/students`,
    edit: (id) => `${api}/students/${id}`,
    delete: (id) => `${api}/students/${id}`
  },
  academicYears: {
    list: `${api}/academicYears`,
    view: (id) => `${api}/academicYears/view/${id}`,
    add: `${api}/academicYears`,
    edit: (id) => `${api}/academicYears/edit/${id}`,
    delete: (id) => `${api}/academicYears/delete/${id}`
  },
  class: {
    list: `${api}/student-classes`,
    view: (id) => `${api}/student-classes/view/${id}`,
    add: `${api}/student-classes/add`,
    edit: (id) => `${api}/student-classes/edit/${id}`,
    delete: (id) => `${api}/student-classes/delete/${id}`
  },
  sections: {
    list: `${api}/sections`,
    view: (id) => `${api}/sections/view/${id}`,
    add: `${api}/sections/add`,
    edit: (id) => `${api}/sections/edit/${id}`,
    delete: (id) => `${api}/sections/delete/${id}`
  },
  subjects: {
    list: `${api}/subjects`,
    view: (id) => `${api}/subjects/view/${id}`,
    add: `${api}/subjects/add`,
    edit: (id) => `${api}/subjects/edit/${id}`,
    delete: (id) => `${api}/subjects/delete/${id}`
  }
};
export default Api; //

export const KY = ky.create({
  hooks: {
    beforeRequest: [
      (req) => {
        req.headers.set('Authorization', sessionStorage.getItem('authKey'));
      }
    ]
  }
});
